import { z } from 'zod'

import { selectVendorSchema } from '@repo/db/schema'
import { FormDataSchema } from '~/utils/form'

export const UpdateVendorTagSchema = z.object({
  intent: z.literal('updateVendorTags'),
  vendorId: selectVendorSchema.shape.id,
  tagIds: FormDataSchema.shape.numberList
})
